"use strict";

import "regulus-oscar";
import "bootstrap/js/dist/popover";
import "./photoswipe";

$(function(){
    $('.js-copy-url').click(function(e){
        e.preventDefault();
        var el = $(this);
        navigator.clipboard.writeText(window.location.href).then(function() {
            el.tooltip({title: 'Link copied to clipboard', trigger: 'manual'}).tooltip('show');
            setTimeout(function(){el.tooltip('hide')}, 3000);
        });
    });


    // Popover on large screens, modal on small
    if($(window).width() <= 992) {
        $('.finish-card').click(function(e){
            e.preventDefault();
            var a = $(this);
            var modalDiv = $('#finishes-modal');
            modalDiv.find('.modal-title').text(a.attr('title'));
            modalDiv.find('.modal-body').html('<img src="' + a.data('url') + '" alt="" class="img-fluid d-block mx-auto">');
            modalDiv.modal('show');
        });
    } else {
        var getPopoverContent = function() {
            return '<img src="' + $(this).data('url') + '" alt="" class="img-fluid d-block mx-auto">';
        };
        $('.finish-card').popover({
            content: getPopoverContent,
            html: true,
            container: 'body',
            trigger: 'hover',
        });
    }
});

//sliders
$(function () {
    $('.slider').each(function (_, element) {
      const slider = $(element);
      const sliders = slider.find('.slider-container');

      slider.find('.previous').click(function () {
          for (let i = 0; i < sliders.length; i++) {
              sliders[i].scrollBy({
                top: 0,
                left: -275,
                behavior: 'smooth'
              });
          }
      });

      slider.find('.next').click(function () {
          for (let i = 0; i < sliders.length; i++) {
              sliders[i].scrollBy({
                top: 0,
                left: 275,
                behavior: 'smooth'
              });
          }
      });

    });
  });